import React from 'react';
import Slider from 'react-slick';
import './Popular.css';
import data_product from '../Assets/data';
import Item from '../Item/Item';


const Popular = () => {
  const settings = {
    slidesToShow: 3,         // Adjust based on your layout
    slidesToScroll: 1,        // Number of items to scroll
    infinite: true,          // Disable infinite scroll if unnecessary
    dots: true,               // Show dots
    arrows: false,             // Enable next/prev arrows
    autoplay: true,           // Enable auto-play if needed
    autoplaySpeed: 3000,      // Speed for auto-play
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div className="popular">
      <h1>POPULAR IN WOMEN</h1>
      <hr />
      <div className="popular-item">
        <Slider {...settings}>
          {data_product.map((item, i) => (
            <Item
              key={i}
              id={item.id}
              name={item.name}
              image={item.image}
              new_price={item.new_price}
              old_price={item.old_price}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Popular;
