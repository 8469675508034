import React from 'react'
import './DescriptionBox.css'
const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-nav-box">
                Description
            </div>
            <div className="descriptionbox-nav-box">
                Reviews (122)
            </div>
        </div>
        <div className="descriptionbox-description">
            <p>Turn heads this summer with the perfect set of summer dresses. Our dresses are designed to make you feel like the confident, stylish woman you were destined to be.</p>
            <p>The Lace Reflection Dresses are lined with soft, breathable tulle fabric and adorned with airy lace. They feature classical detailing in a range of colors, letting you bring a classic touch to summer trends.</p>
            <p>The skirt length is low enough to be modest but just high enough to give your legs a more extended and slimmer look. It can be perfectly paired with your favorite pair of flats because of its length.</p>
        </div>
    </div>
  )
}

export default DescriptionBox